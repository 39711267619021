import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle, faClock } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import _set from "lodash/set";
import { Portal } from "react-overlays";
import ThreeDotsToggle from "../Table/ThreeDotsToggle";
import { updateFirmwideParticipation } from "../../../api/diversityApi";
import DiversityFirmwideUploadActionModal from "../../../pages/DiversityFirmwideUploadPage/components/DiversityFirmwideUploadActionModal";
import I18n from "../../../utils/i18n";

const ActionsCell = ({ row: { index, original }, updateData, firm, containerRef }) => {
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);

    const onSubmit = (values, { setSubmitting, setErrors }) =>
        updateFirmwideParticipation(
            original.id,
            _set(values, "participation.comments_attributes[0]", {
                ...values.participation.comments_attributes[0],
                author_id: firm.id,
                author_type: "Diversity::Firm",
                action: `participation_${values.participation.status}`,
            }),
        )
            .then((response) => {
                setSubmitting(false);
                setShowApproveModal(false);
                setShowRejectModal(false);
                setShowReviewModal(false);
                updateData(index, response.data);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                setSubmitting(false);
            });

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Portal container={containerRef}>
                    <Dropdown.Menu size="sm">
                        {original.status === "requested" && (
                            <Dropdown.Item onClick={() => setShowReviewModal(true)}>
                                <FontAwesomeIcon icon={faClock} className="mr-2" />
                                {I18n.t("tables.diversity/participation.actions.review")}
                            </Dropdown.Item>
                        )}
                        {original.status !== "approved" && (
                            <Dropdown.Item onClick={() => setShowApproveModal(true)}>
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                {I18n.t("tables.diversity/participation.actions.approve")}
                            </Dropdown.Item>
                        )}
                        {original.status !== "rejected" && (
                            <Dropdown.Item onClick={() => setShowRejectModal(true)}>
                                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                {I18n.t("tables.diversity/participation.actions.reject")}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Portal>
            </Dropdown>
            {showRejectModal && (
                <DiversityFirmwideUploadActionModal
                    show={showRejectModal}
                    status="rejected"
                    onSubmit={onSubmit}
                    onHide={() => setShowRejectModal(false)}
                />
            )}
            {showApproveModal && (
                <DiversityFirmwideUploadActionModal
                    show={showApproveModal}
                    status="approved"
                    comment={firm.auto_approve_comment}
                    onSubmit={onSubmit}
                    onHide={() => setShowApproveModal(false)}
                />
            )}
            {showReviewModal && (
                <DiversityFirmwideUploadActionModal
                    show={showReviewModal}
                    status="reviewed"
                    comment={firm.auto_approve_comment}
                    onSubmit={onSubmit}
                    onHide={() => setShowReviewModal(false)}
                />
            )}
        </>
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
    firm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        auto_approve_comment: PropTypes.string,
    }),
    containerRef: PropTypes.object,
};

export default ActionsCell;
