import React, { useState } from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _toString from "lodash/toString";
import _includes from "lodash/includes";
import _lowerCase from "lodash/lowerCase";
import _groupBy from "lodash/groupBy";
import _range from "lodash/range";
import _every from "lodash/every";
import _union from "lodash/union";
import _without from "lodash/without";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _concat from "lodash/concat";
import _sum from "lodash/sum";
import _keys from "lodash/keys";
import _get from "lodash/get";
import _intersection from "lodash/intersection";
import _find from "lodash/find";
import { useField } from "formik";
import { Form, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import _sumBy from "lodash/sumBy";
import IndeterminateCheck from "../../../../common/components/IndeterminateCheck";
import PinIndicator from "./PinIndicator";
import I18n from "../../../../utils/i18n";

const NUMBER_OF_VISIBLE_ELEMENTS = 3;

const GroupSelectMenu = ({
    title,
    inputName,
    elements,
    fetching,
    setFieldValue,
    values,
    pinned,
    marked,
    pinFunction,
    counter,
}) => {
    const [field, meta, helper] = useField(inputName);
    const [searchValue, setSearchValue] = useState("");

    const isSearchMatched = (value) =>
        searchValue.length >= 3 ? _includes(_lowerCase(value), _lowerCase(searchValue)) : true;
    const onSearch = (event) => setSearchValue(event.target.value);

    const onClear = () => {
        setSearchValue("");
        helper.setValue([]);
    };

    const getFilteredElements = (elements) =>
        _filter(
            elements,
            (element) =>
                isSearchMatched(element.group_name) ||
                (!!element.subgroup_name && isSearchMatched(element.subgroup_name)) ||
                (!!element.name && isSearchMatched(element.name)),
        );

    const getGroupedElements = (elements, groupValue) =>
        _groupBy(getFilteredElements(elements), (filteredElement) => filteredElement[groupValue] || "");
    const isChecked = (element) => _includes(meta.value, _toString(element.id));
    const areChecked = (elements) => _every(elements, isChecked);
    const areIndeterminate = (elements) => _some(elements, isChecked) && !areChecked(elements);

    const onSelectGroup = (event, elements) => {
        const values = _map(elements, (item) => _toString(item.id));
        helper.setValue(event.target.checked ? _union(meta.value, values) : _without(meta.value, ...values));
    };

    const onSelectAll = (event) => onSelectGroup(event, elements);

    const selectFocus = (subgroupedElements, element, event) => {
        if (event.target.checked) {
            const focus = _find(subgroupedElements, ["name", null]);

            if (_includes(values, focus.id)) {
                field.onChange(event);
            } else {
                const newValues = _concat(
                    values,
                    _map([focus, element], (item) => _toString(item.id)),
                );
                setFieldValue(inputName, newValues);
            }
        } else {
            field.onChange(event);
        }
    };

    const isPinned = (name) => _includes(pinned, name);
    const isMarked = (name) => _includes(marked, name);

    const getCounterForGroup = (elements) => {
        const selectedGroup = _intersection(
            _keys(counter),
            _map(elements, (item) => _toString(item.id)),
        );

        return _sum(_filter(counter, (v, k) => _includes(selectedGroup, k) && v));
    };

    const labelFor = (name, elements, size) => {
        const count = _sumBy(elements, "count");
        return count
            ? `${name} (${count})`
            : I18n.t("companies.profiles.sections.filters.counter", {
                  name: name,
                  count: size || 0,
              });
    };

    return (
        <div className="group-select-menu">
            <div className="d-flex align-items-center justify-content-between">
                <strong>{title}</strong>
                <small className="text-muted cursor-pointer" onClick={onClear}>
                    {I18n.t("common.clear")}
                </small>
            </div>
            {fetching ? (
                <Form className="mt-2">
                    {_map(_range(NUMBER_OF_VISIBLE_ELEMENTS), (item) => (
                        <Skeleton key={`loader-${inputName}-${item}`} />
                    ))}
                </Form>
            ) : (
                <Form className="mt-2">
                    <Form.Control
                        placeholder={I18n.t("common.placeholders.search")}
                        onChange={onSearch}
                        value={searchValue}
                        className="mb-2"
                    />
                    <IndeterminateCheck
                        key={`${inputName}-all`}
                        type="checkbox"
                        name={inputName}
                        id={`${inputName}-all`}
                        label={I18n.t("companies.profiles.sections.filters.counter", {
                            name: I18n.t("common.select_all"),
                            count: counter?.total,
                        })}
                        onChange={onSelectAll}
                        className="mb-2"
                        checked={areChecked(elements)}
                        indeterminate={areIndeterminate(elements)}
                    />
                    <div className="checkbox-list">
                        {_map(getGroupedElements(elements, "group_name"), (groupedElements, groupName) => (
                            <Accordion key={`${inputName}-${groupName}`}>
                                <Accordion.Toggle
                                    as="div"
                                    className="d-inline-block"
                                    eventKey={`${inputName}-${groupName}`}
                                >
                                    <div
                                        className={classNames("form-check-inline d-on-hover", {
                                            "font-weight-bold": isMarked(groupName),
                                        })}
                                    >
                                        <IndeterminateCheck
                                            className="multiselect-filter d-inline-block text-truncate w-100"
                                            type="checkbox"
                                            id={`${inputName}-${groupName}`}
                                            checked={areChecked(groupedElements)}
                                            indeterminate={areIndeterminate(groupedElements)}
                                            onChange={(event) => onSelectGroup(event, groupedElements)}
                                            label={labelFor(
                                                groupName,
                                                groupedElements,
                                                getCounterForGroup(groupedElements),
                                            )}
                                        />
                                        {_filter(groupedElements, "subgroup_name").length > 0 && (
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                className="align-baseline ml-2 arrow"
                                                size="xs"
                                            />
                                        )}
                                        <PinIndicator
                                            pageName={inputName}
                                            isPinned={isPinned(groupName)}
                                            pinFunction={pinFunction}
                                            element={groupName}
                                        />
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={`${inputName}-${groupName}`}>
                                    <div>
                                        {_map(
                                            getGroupedElements(groupedElements, "subgroup_name"),
                                            (subgroupedElements, subgroupName) => (
                                                <Accordion
                                                    key={`${inputName}-${groupName}-${subgroupName}`}
                                                    className={classNames({ "d-none": !subgroupName })}
                                                >
                                                    <div className="ml-4">
                                                        <Accordion.Toggle
                                                            as="div"
                                                            className="d-inline-block"
                                                            eventKey={`${inputName}-${groupName}-${subgroupName}`}
                                                        >
                                                            <div
                                                                className={classNames("form-check-inline d-on-hover", {
                                                                    "font-weight-bold": isMarked(
                                                                        `${groupName}_${subgroupName}`,
                                                                    ),
                                                                })}
                                                            >
                                                                <IndeterminateCheck
                                                                    className="multiselect-filter d-inline-block text-truncate w-100"
                                                                    type="checkbox"
                                                                    id={`${inputName}-${groupName}-${subgroupName}`}
                                                                    checked={areChecked(subgroupedElements)}
                                                                    indeterminate={areIndeterminate(subgroupedElements)}
                                                                    onChange={(event) =>
                                                                        onSelectGroup(event, subgroupedElements)
                                                                    }
                                                                    label={labelFor(
                                                                        subgroupName,
                                                                        subgroupedElements,
                                                                        getCounterForGroup(subgroupedElements),
                                                                    )}
                                                                />
                                                                {_filter(subgroupedElements, "name").length > 0 && (
                                                                    <FontAwesomeIcon
                                                                        icon={faChevronDown}
                                                                        className="align-baseline ml-2 arrow"
                                                                        size="xs"
                                                                    />
                                                                )}
                                                                <PinIndicator
                                                                    pageName={inputName}
                                                                    isPinned={isPinned(`${groupName}_${subgroupName}`)}
                                                                    pinFunction={pinFunction}
                                                                    element={`${groupName}_${subgroupName}`}
                                                                />
                                                            </div>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse
                                                            eventKey={`${inputName}-${groupName}-${subgroupName}`}
                                                        >
                                                            <div className="ml-4">
                                                                {_map(subgroupedElements, (element) => (
                                                                    <div
                                                                        key={element.id}
                                                                        className={classNames(
                                                                            "form-check-inline",
                                                                            "d-on-hover",
                                                                            "w-100",
                                                                            "mr-0",
                                                                            {
                                                                                "d-none": !element.name,
                                                                                "font-weight-bold": isMarked(
                                                                                    `${groupName}_${subgroupName}_${element.name}`,
                                                                                ),
                                                                            },
                                                                        )}
                                                                    >
                                                                        <Form.Check custom>
                                                                            <Form.Check.Input
                                                                                className="multiselect-filter d-inline-block text-truncate"
                                                                                onChange={(event) =>
                                                                                    selectFocus(
                                                                                        subgroupedElements,
                                                                                        element,
                                                                                        event,
                                                                                    )
                                                                                }
                                                                                name={field.name}
                                                                                type="checkbox"
                                                                                id={element.id}
                                                                                value={element.id}
                                                                                checked={isChecked(element)}
                                                                            />
                                                                            <Form.Check.Label
                                                                                htmlFor={element.id}
                                                                                className="w-100"
                                                                            >
                                                                                {labelFor(
                                                                                    element.name || subgroupName,
                                                                                    [element],
                                                                                    _get(counter, element.id),
                                                                                )}
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                        <PinIndicator
                                                                            pageName={inputName}
                                                                            isPinned={isPinned(
                                                                                `${groupName}_${subgroupName}_${element.name}`,
                                                                            )}
                                                                            pinFunction={pinFunction}
                                                                            element={`${groupName}_${subgroupName}_${element.name}`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                </Accordion>
                                            ),
                                        )}
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        ))}
                    </div>
                </Form>
            )}
        </div>
    );
};

GroupSelectMenu.propTypes = {
    title: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            group_name: PropTypes.string.isRequired,
            subgroup_name: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    fetching: PropTypes.bool,
    values: PropTypes.array,
    setFieldValue: PropTypes.func.isRequired,
    pinned: PropTypes.array,
    marked: PropTypes.array,
    pinFunction: PropTypes.func,
    counter: PropTypes.object,
};

export default GroupSelectMenu;
