import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { createOrganization, getNewOrganization } from "../../api/organizationApi";
import { AppLoader } from "../../common/components";
import { setAlert } from "../../state/alert/alertActions";
import OrganizationForm from "../../common/components/OrganizationForm";
import { modifiedIndustryAttributes } from "../../utils/organizationProfile";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import I18n from "../../utils/i18n";

const NewOrganizationPage = ({ title }) => {
    const { parentId } = useParams();
    const history = useHistory();
    const [organization, setOrganization] = useState({});
    const [key, setKey] = useState("generalInfo");

    const backLinkInfo = parentId
        ? {
              backPath: `/organizations/${parentId}/subOrganizations`,
              linkName: I18n.t("forms.sub_organization.title"),
          }
        : { backPath: "/organizations", linkName: I18n.t("sidebar.links.manage.organizations") };

    const dispatch = useDispatch();

    const loadOrganization = (userValues) =>
        getNewOrganization({ parent: parentId }, userValues)
            .then((response) => {
                setOrganization(response.data);
            })
            .catch(() => {
                history.push("/");
            });

    useEffect(loadOrganization, []);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        values.organization.organization_industries_attributes = modifiedIndustryAttributes(values);

        createOrganization({ ...values, ...(parentId && { parent: parentId }) })
            .then((response) => {
                setSubmitting(false);
                setOrganization(response.data);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                history.goBack();
            })
            .catch((error) => {
                setSubmitting(false);
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
            });
    };

    if (_.isEmpty(organization)) {
        return <AppLoader />;
    }

    return (
        <>
            <TopBarWithBackLink backPath={backLinkInfo.backPath} linkName={backLinkInfo.linkName} />
            <OrganizationForm
                id={parentId}
                title={title}
                initialValues={{
                    organization: {
                        ...organization,
                        organization_industries_attributes: organization.organization_industries_attributes.length
                            ? organization.organization_industries_attributes
                            : [{ primary: true, industry_id: "" }],
                    },
                    whitelabel_id: organization.whitelabel_id,
                    whitelabel: organization.whitelabel,
                }}
                onSubmit={onSubmit}
                tab={{ key, setKey }}
            />
        </>
    );
};

NewOrganizationPage.propTypes = {
    title: PropTypes.string.isRequired,
};

export default NewOrganizationPage;
