import React, { useCallback, useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import _isNull from "lodash/isNull";
import AsyncSelect from "react-select/async";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import { createNegotiation } from "../../../../api/negotiationApi";
import { getIndustries } from "../../../../api/industryApi";

const IndustryPickerStep = () => {
    const { setCurrentStep, onHide, exportCompanies, setNegotiationParams } = useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const prevStepIndex = 1;
    const translationScope = "home.create_event_wizard.steps.industry_step";
    const [creating, setCreating] = useState(false);
    const [industryUid, setIndustryUid] = useState(null);
    const exportCompaniesStepIndex = 5;

    const loadOptions = _debounce((term, callback) => {
        if (term && term.length >= 3) {
            getIndustries({ q: { specialty_not_null: true, area_or_focus_or_specialty_cont: term } }).then(({ data }) =>
                callback(_map(_groupBy(data, "area"), (industries, area) => ({ options: industries, label: area }))),
            );
        }
    }, 500);

    const proceedHandler = useCallback(() => {
        if (exportCompanies?.length > 0) {
            setNegotiationParams({ type: "legal", industry_uid: industryUid });
            return setCurrentStep(exportCompaniesStepIndex);
        }

        setCreating(true);
        createNegotiation({ legal: true, industry_uid: industryUid })
            .then(
                ({ data: { link } }) =>
                    (window.location = `//${currentWhitelabel.hosts.bid}${link}/general_settings?wizard=true`),
            )
            .catch(() => setCreating(false));
    }, [industryUid]);

    return (
        <>
            <Modal.Header className="border-0 pb-1">
                <Modal.Title className="mb-3">
                    <Link className="text-dark" to="#" onClick={() => setCurrentStep(prevStepIndex)}>
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                        {I18n.t("home.create_event_wizard.buttons.back")}
                    </Link>
                    <div className="mt-4 mb-2 text-2xl">{I18n.t("title", { scope: translationScope })}</div>
                    <div className="tracking-wide text-base font-normal font-italic text-grey-50">
                        {I18n.t("tip", { scope: translationScope })}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb-4">
                <AsyncSelect
                    className="react-select flex-grow-1"
                    classNamePrefix="react-select"
                    noOptionsMessage={({ inputValue }) =>
                        inputValue.length > 0
                            ? I18n.t("common.placeholders.no_options")
                            : I18n.t("common.placeholders.start_typing")
                    }
                    getOptionLabel={(option) => option.specialty || option.focus}
                    getOptionValue={(option) => option.uid}
                    formatGroupLabel={(option) => <div>{option.label}</div>}
                    formatOptionLabel={function (option, meta) {
                        if (meta.context === "value") {
                            return <div title={option.name}>{option.name}</div>;
                        } else {
                            return (
                                <div className={option.specialty ? "ml-4" : "ml-2"}>{this.getOptionLabel(option)}</div>
                            );
                        }
                    }}
                    loadOptions={loadOptions}
                    onChange={(option) => setIndustryUid(option.uid)}
                />
            </Modal.Body>

            <Modal.Footer className="border-0 d-flex justify-content-between">
                <Button variant="outline-secondary" onClick={onHide}>
                    {I18n.t("home.create_event_wizard.buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    className="text-white"
                    onClick={proceedHandler}
                    disabled={_isNull(industryUid)}
                >
                    <Spinner animation="border" size="sm" className="mr-2" hidden={!creating} />
                    {I18n.t("home.create_event_wizard.buttons.proceed")}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default IndustryPickerStep;
