import React, { useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { Col, FormControl, Row, Form, Button } from "react-bootstrap";
import { Field, getIn } from "formik";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import AsyncCreatableSelect from "react-select/async-creatable";
import classNames from "classnames";
import { getAutocompleteActor } from "../../api/actorApi";
import { getOrganizationsBy } from "../../api/organizationApi";
import I18n from "../../utils/i18n";

function selectStyles(error) {
    return {
        control: (styles) => ({
            ...styles,
            borderColor: error ? "red" : styles.borderColor,
            "&:hover": {
                borderColor: error ? "red" : styles["&:hover"].borderColor,
            },
        }),
    };
}

const FirmFields = ({ values, firm, index, setFieldValue, field, handleBlur, handleRemove, errors }) => {
    const minCharactersLength = 3;
    const emailInput = `${field}[${index}].email`;
    const nameInput = `${field}[${index}].name`;
    const firstNameInput = `${field}[${index}].first_name`;
    const lastNameInput = `${field}[${index}].last_name`;
    const firmUid = `${field}[${index}].uid`;
    const [options, setOptions] = useState([]);

    const fetchAutocompleteOrganization = (term) =>
        _isEmpty(options) && (term || "").trim().length >= minCharactersLength
            ? getOrganizationsBy({ term: term }).then((response) =>
                  _map(response.data, (org) => ({ value: org.uid, label: org.name })),
              )
            : new Promise((resolve) =>
                  resolve(_filter(options, (option) => _includes(option.label.toLowerCase(), term.toLowerCase()))),
              );

    const fetchAutocompleteUser = (email) => {
        getAutocompleteActor({ email: email }).then(({ data }) => {
            if (_isEmpty(data.organizations)) {
                setOptions([]);
                _forEach([nameInput, firstNameInput, lastNameInput], (field) => setFieldValue(field, ""));
                setFieldValue(firmUid, null);
            } else {
                if (data.organizations.length === 1) {
                    setFieldValue(nameInput, data.organizations[0].name);
                    setFieldValue(firmUid, data.organizations[0].uid);
                } else {
                    setFieldValue(nameInput, "");
                    setFieldValue(firmUid, null);
                }
                let optionsValues = _map(data.organizations, (org) => ({ value: org.uid, label: org.name }));
                setOptions(optionsValues);
                setFieldValue(firstNameInput, _isEmpty(data.user_attributes) ? "" : data.user_attributes.first_name);
                setFieldValue(lastNameInput, _isEmpty(data.user_attributes) ? "" : data.user_attributes.last_name);
            }
        });
    };

    return (
        <div key={index}>
            <Row>
                <Col>
                    <Row className="bottom-border">
                        <Col>
                            <Form.Group>
                                <Field
                                    as={FormControl}
                                    name={emailInput}
                                    value={firm.email}
                                    type="email"
                                    placeholder={I18n.t("diversity.clients.steps.firm_email")}
                                    className="mb-2"
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        _isEmpty(getIn(errors, emailInput)) &&
                                            !_isEmpty(event.target.value) &&
                                            fetchAutocompleteUser(event.target.value);
                                    }}
                                    isInvalid={getIn(errors, emailInput)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, emailInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <AsyncCreatableSelect
                                    cacheOptions
                                    allowCreateWhileLoading={false}
                                    defaultOptions={options}
                                    name={nameInput}
                                    isValidNewOption={(value) =>
                                        _isEmpty(options) && (value || "").trim().length >= minCharactersLength
                                    }
                                    loadOptions={fetchAutocompleteOrganization}
                                    value={
                                        _isEmpty(getIn(errors, emailInput)) &&
                                        !_isEmpty(firm.email) &&
                                        !_isEmpty(firm.name) && { label: firm.name, value: firm.uid }
                                    }
                                    onChange={(option) => {
                                        setFieldValue(nameInput, option.label);
                                        !option.__isNew__ && setFieldValue(firmUid, option.value);
                                    }}
                                    onBlur={handleBlur}
                                    placeholder={
                                        !_isEmpty(firm.email) && options.length > 1
                                            ? I18n.t("diversity.clients.steps.choose_firm")
                                            : I18n.t("diversity.columns.firm_name")
                                    }
                                    className={classNames("react-select", {
                                        "is-invalid": !_isEmpty(getIn(errors, emailInput)),
                                    })}
                                    styles={selectStyles(getIn(errors, emailInput) ? "" : getIn(errors, nameInput))}
                                    isDisabled={_isEmpty(values[index].email) || getIn(errors, emailInput)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, emailInput) ? "" : getIn(errors, nameInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Field
                                    as={FormControl}
                                    name={firstNameInput}
                                    value={firm.first_name}
                                    type="text"
                                    placeholder={I18n.t("activerecord.attributes.user.first_name")}
                                    isInvalid={getIn(errors, firstNameInput)}
                                    disabled={_isEmpty(values[index].email) || getIn(errors, emailInput)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, firstNameInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Field
                                    as={FormControl}
                                    name={lastNameInput}
                                    value={firm.last_name}
                                    type="text"
                                    placeholder={I18n.t("activerecord.attributes.user.last_name")}
                                    isInvalid={getIn(errors, lastNameInput)}
                                    disabled={_isEmpty(values[index].email) || getIn(errors, emailInput)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, lastNameInput)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {values.length - 1 !== index && <hr />}
                </Col>
                {index > 0 && (
                    <Button size="lg" variant="light" className="h-100 mr-3" onClick={() => handleRemove(index)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                )}
            </Row>
        </div>
    );
};

FirmFields.propTypes = {
    values: PropTypes.array.isRequired,
    firm: PropTypes.shape({
        uid: PropTypes.string,
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    handleBlur: PropTypes.func,
    handleRemove: PropTypes.func,
    errors: PropTypes.object,
};

export default FirmFields;
