import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, Col } from "react-bootstrap";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import Paragraph from "./content/Paragraph";
import {
    ContactInfo,
    CompanyStructure,
    Ownership,
    SalesAndEmployees,
    IndustriesList,
    CertificatesList,
    Description,
} from "./general/index";
import I18n from "../../../utils/i18n";

const GeneralInfo = ({ data }) => (
    <Card className="mb-2 border-top-0 rounded-top-0">
        <Card.Body>
            <Paragraph title={I18n.t("activerecord.attributes.organization.description")}>
                {data.description && <Description value={data.description} />}
            </Paragraph>
            {!_isEmpty(data.locations) && (
                <Paragraph title={I18n.t("companies.profiles.paragraphs.contact_information.title")}>
                    <ContactInfo location={_find(data.locations, ["location_type_required", true])} />
                </Paragraph>
            )}
            <Paragraph title={I18n.t("activerecord.models.company_structure")}>
                <CompanyStructure values={data.company_structure_attributes} />
            </Paragraph>
            {data.industries && (
                <Paragraph title={I18n.t("activerecord.models.industry")}>
                    <IndustriesList industries={data.industries} />
                </Paragraph>
            )}

            {data.certificates && (
                <Paragraph title={I18n.t("activerecord.models.diversity/certificate")}>
                    <Col md={6} className="p-0">
                        <CertificatesList certificates={data.certificates} />
                    </Col>
                </Paragraph>
            )}
            <Paragraph title={I18n.t("forms.organization.edit.sections.ownership")}>
                <Ownership values={data.ownership_attributes} />
            </Paragraph>
            <Paragraph>
                <SalesAndEmployees values={data.company_structure_attributes} />
            </Paragraph>
        </Card.Body>
        <Card.Footer>
            {I18n.t("companies.profiles.text.updated", { date: moment(data.updated_at).fromNow() })}
        </Card.Footer>
    </Card>
);

GeneralInfo.propTypes = {
    data: PropTypes.shape({
        description: PropTypes.string,
        company_structure_attributes: PropTypes.object,
        ownership_attributes: PropTypes.object,
        updated_at: PropTypes.string,
        industries: PropTypes.arrayOf(
            PropTypes.shape({
                primary: PropTypes.bool,
                name: PropTypes.string,
                area: PropTypes.string,
                focus: PropTypes.string,
                specialty: PropTypes.string,
            }),
        ),
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                location_type_name: PropTypes.string.isRequired,
                location_type_required: PropTypes.bool.isRequired,
                address_line1: PropTypes.string,
                address_line2: PropTypes.string,
                country: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                    name: PropTypes.string,
                }),
                state: PropTypes.shape({
                    code: PropTypes.string,
                    name: PropTypes.string,
                }),
                zip_code: PropTypes.string,
                city: PropTypes.string,
                phone: PropTypes.string,
                email: PropTypes.string,
            }),
        ),
        certificates: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                expiration_date: PropTypes.string,
                origin_date: PropTypes.string,
                primary: PropTypes.bool,
                description: PropTypes.string,
                source: PropTypes.string,
                name: PropTypes.string,
            }),
        ),
    }),
};

export default GeneralInfo;
