import _reject from "lodash/reject";
import _isEqual from "lodash/isEqual";
import _uniqWith from "lodash/uniqWith";

export function modifiedIndustryAttributes({ organization }) {
    const uniqIndustryAttributes = _uniqWith(organization.organization_industries_attributes, _isEqual);

    if (uniqIndustryAttributes.length === 1 && _isEqual(uniqIndustryAttributes[0], { industry_id: "" })) return null;
    else return _reject(uniqIndustryAttributes, ["industry_id", ""]);
}
