import qs from "qs";
import API from "../utils/api";

export function getCompaniesProfile(uid) {
    return API.get(`/companies/profiles/${uid}`);
}

export function getCompaniesProfiles(params) {
    return API.get(
        `/companies/profiles?${qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false,
        })}`
    );
}

export function getCompaniesFilters() {
    return API.get("/companies/filters");
}

export function pinFilter(params) {
    return API.post("/personal_settings", params);
}

export function unpinFilter(params) {
    return API.delete("/personal_settings", { data: params });
}
