import React, { useCallback, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import I18n from "../../../../utils/i18n";
import { getNegotiations } from "../../../../api/informationApi";
import NegotiationsTable from "./NegotiationsTable";
import NewProjectButton from "../NewProjectButton";

const Negotiations = () => {
    const [negotiations, setNegotiations] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [page, setPage] = useState(1);
    const defaultSortBy = { id: "response_due", desc: true };
    const [pagesCount, setPagesCount] = useState(0);
    const goToPage = ({ selected }) => setPage(selected + 1);

    const fetchData = useCallback(
        ({ sortBy }) => {
            const sort = sortBy[0] || defaultSortBy;
            setIsFetching(true);

            getNegotiations({
                q: {
                    page: page,
                    order_by: sort.id,
                    ordering: sort.desc ? "desc" : "asc",
                },
            })
                .then(({ data: { records, pages_count } }) => {
                    setNegotiations(records);
                    setPagesCount(pages_count);
                })
                .catch(() => setNegotiations([]))
                .finally(() => setIsFetching(false));
        },
        [page],
    );

    return (
        <>
            <h3>{I18n.t("home.negotiations.header")}</h3>
            <Card className="mb-2">
                <Card.Body>
                    <Row className="mb-4">
                        <Col className="d-flex justify-content-end">
                            <NewProjectButton />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NegotiationsTable fetchData={fetchData} isFetching={isFetching} data={negotiations} />
                            {pagesCount > 1 && (
                                <div className="pagination justify-content-center mb-3">
                                    <ReactPaginate
                                        previousLabel="«"
                                        nextLabel="»"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pagesCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={goToPage}
                                        containerClassName="pagination pagination-sm mb-0"
                                        pageClassName="page-item"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        nextClassName="page-item"
                                        pageLinkClassName="page-link"
                                        activeClassName="active"
                                        forcePage={page - 1}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Negotiations;
