import React from "react";
import { Container } from "react-bootstrap";
import _get from "lodash/get";
import { useFormikContext } from "formik";
import {
    GeneralSection,
    CompanyStructureSection,
    OwnershipSection,
    IndustrySection,
    IndicatorSection,
} from "../sections";
import I18n from "../../../../utils/i18n";

const GeneralInfoTab = () => {
    const { errors, values } = useFormikContext();
    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <GeneralSection errors={errors.organization || {}} values={values.organization} />
            <CompanyStructureSection
                errors={_get(errors, "organization.company_structure_attributes", _get(errors, "organization", {}))}
                values={values.organization.company_structure_attributes}
            />
            <IndicatorSection
                label={I18n.t("activerecord.models.sales_indicator")}
                valueLabel={I18n.t("activerecord.attributes.sales_indicator.value")}
                errors={_get(errors, "organization.company_structure_attributes.sales_indicators_attributes") || []}
                inputName="organization[company_structure_attributes][sales_indicators_attributes]"
                values={values.organization.company_structure_attributes.sales_indicators_attributes}
            />
            <IndicatorSection
                label={I18n.t("activerecord.models.employees_indicator")}
                valueLabel={I18n.t("activerecord.attributes.employees_indicator.value")}
                errors={_get(errors, "organization.company_structure_attributes.employees_indicators_attributes") || []}
                inputName="organization[company_structure_attributes][employees_indicators_attributes]"
                values={values.organization.company_structure_attributes.employees_indicators_attributes}
            />
            <OwnershipSection
                errors={_get(errors, "organization.company_structure_attributes") || {}}
                values={values.organization.ownership_attributes}
            />
            <IndustrySection
                errors={_get(errors, "organization.organization_industries_attributes") || []}
                values={values.organization.organization_industries_attributes}
                menuPlacement="top"
            />
        </Container>
    );
};

export default GeneralInfoTab;
