import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import _map from "lodash/map";
import I18n from "../../../../utils/i18n";

const IndustriesList = ({ industries }) => {
    const getName = (industry) =>
        industry.primary
            ? I18n.t("companies.profiles.paragraphs.industries.primary_name", { name: industry.name })
            : industry.name;

    return (
        <div>
            {_map(industries, (industry) => (
                <Row key={industry.name} className="mt-3">
                    <Col className={classNames({ "font-weight-bold": industry.primary })}>{getName(industry)}</Col>
                </Row>
            ))}
        </div>
    );
};

IndustriesList.propTypes = {
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            name: PropTypes.string,
            area: PropTypes.string,
            focus: PropTypes.string,
            specialty: PropTypes.string,
        })
    ),
};

export default IndustriesList;
