import React, { useState } from "react";
import classNames from "classnames";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import Select from "react-select";
import { useFormikContext } from "formik";
import { Row, Col } from "react-bootstrap";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _uniqueId from "lodash/uniqueId";
import _findIndex from "lodash/findIndex";
import _reject from "lodash/reject";
import _omit from "lodash/omit";
import _isNumber from "lodash/isNumber";
import _forEach from "lodash/forEach";
import PropTypes from "prop-types";
import { getIndustries } from "../../../../api/industryApi";
import I18n from "../../../../utils/i18n";

const IndustryInput = ({ errors, value, menuPlacement, index, push, values }) => {
    const { setFieldTouched, setFieldValue } = useFormikContext();
    const [focus, setFocus] = useState(value?.focus);

    const setDestroyFor = (valueIndex, destroy) => {
        setFieldValue(`organization[organization_industries_attributes][${valueIndex}][_destroy]`, destroy);
        destroy && setFieldValue(`organization[organization_industries_attributes][${valueIndex}][primary]`, !destroy);
    };

    const loadOptions = debounce((term, callback) => {
        if (term && term.length >= 3) {
            getIndustries({ q: { area_or_focus_or_specialty_cont: term } }).then((response) =>
                callback(
                    _map(_groupBy(response.data, "area"), (industries, area) => ({ options: industries, label: area }))
                )
            );
        }
    }, 500);

    const loadSpecialty = (focus) => {
        getIndustries({ q: { focus_eq: encodeURIComponent(focus) } }).then((response) => {
            if (response.data.length > 1) {
                const options = _map(
                    _filter(response.data, (record) => record.specialty !== null),
                    (option) => ({ ...option, industry_id: option.id })
                );

                _map(options, (option) => {
                    const industryIndex = _findIndex(values, ["industry_id", option.industry_id]);
                    industryIndex >= 0
                        ? values[industryIndex]._destroy && setDestroyFor(industryIndex, false)
                        : push(_omit(option, "id"));
                });
            }
        });
    };

    const onChange = (index, option) => {
        setFieldValue(`organization[organization_industries_attributes][${index}][name]`, option.name, false);
        setFieldValue(`organization[organization_industries_attributes][${index}][area]`, option.area, false);
        setFieldValue(`organization[organization_industries_attributes][${index}][focus]`, option.focus, false);
        setFieldValue(`organization[organization_industries_attributes][${index}][specialty]`, option.specialty, false);
        setFieldValue(`organization[organization_industries_attributes][${index}][industry_id]`, option.id);
    };

    const getSpecialtyOptions = () => {
        const existFocusIndex = _findIndex(values, (v) => v.focus === focus && _isEmpty(v.specialty) && !v._destroy);
        return existFocusIndex >= 0 && existFocusIndex === index
            ? _filter(values, (v) => v.focus === focus && !_isEmpty(v.specialty))
            : [];
    };

    return (
        <>
            <AsyncSelect
                name={`organization[organization_industries_attributes][${index}][industry_id]`}
                className={classNames("react-select", {
                    "is-invalid": errors[index] && errors[index].industry_id,
                })}
                classNamePrefix="react-select"
                menuPlacement={menuPlacement}
                noOptionsMessage={({ inputValue }) =>
                    inputValue.length > 0
                        ? I18n.t("common.placeholders.no_options")
                        : I18n.t("common.placeholders.start_typing")
                }
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.specialty || option.focus}
                getOptionValue={(option) => option.id}
                formatGroupLabel={(option) => <div>{option.label}</div>}
                formatOptionLabel={function (option, meta) {
                    if (meta.context === "value") {
                        return <div title={option.name}>{option.name}</div>;
                    } else {
                        return <div className={option.specialty ? "ml-4" : "ml-2"}>{this.getOptionLabel(option)}</div>;
                    }
                }}
                value={_isNumber(value.industry_id) && { ...value, industry_id: value?.id }}
                onChange={(option) => {
                    const industryIndex = _findIndex(values, ["industry_id", option.id]);
                    industryIndex >= 0 ? setDestroyFor(industryIndex, false) : onChange(index, option);

                    if (_isNumber(option.id) && _isEmpty(option.specialty)) {
                        setFocus(option.focus);
                        loadSpecialty(option.focus);
                    }
                }}
                onBlur={() =>
                    setFieldTouched(`organization[organization_industries_attributes][${index}][industry_id]`)
                }
            />

            {!_isEmpty(getSpecialtyOptions()) && (
                <Row className="mt-3">
                    <Col className="ml-5">
                        <Select
                            isMulti
                            id={_uniqueId("specialty_")}
                            classNamePrefix="react-select"
                            menuPlacement={menuPlacement}
                            options={getSpecialtyOptions()}
                            getOptionLabel={(option) => option.specialty}
                            getOptionValue={(option) => option.industry_id}
                            value={_reject(getSpecialtyOptions(), ["_destroy", true])}
                            onChange={(option, meta) => {
                                if (meta.action === "remove-value") {
                                    const industryIndex = _findIndex(values, [
                                        "industry_id",
                                        meta.removedValue.industry_id,
                                    ]);
                                    setDestroyFor(industryIndex, true);
                                }
                                if (meta.action === "clear") {
                                    _forEach(meta.removedValues, (value) => {
                                        const industryIndex = _findIndex(values, ["industry_id", value.industry_id]);
                                        setDestroyFor(industryIndex, true);
                                    });
                                }
                                if (meta.action === "select-option") {
                                    const industryIndex = _findIndex(values, ["industry_id", meta.option.industry_id]);

                                    setDestroyFor(industryIndex, false);
                                }
                            }}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

IndustryInput.propTypes = {
    errors: PropTypes.array,
    value: PropTypes.shape({
        focus: PropTypes.string,
        id: PropTypes.number,
        industry_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    menuPlacement: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    push: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
};

export default IndustryInput;
