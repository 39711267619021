import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import Select from "react-select";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import I18n from "../../utils/i18n";
import { getSelectOrganizations } from "../../api/loginApi";
import { setCurrentActor, setCurrentActorByOrganization } from "../../state/currentActor/currentActorActions";

const SwitchOrganizationPage = () => {
    const dispatch = useDispatch();
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        dispatch(setCurrentActor({}));
        getSelectOrganizations().then((response) => setOrganizations(response.data));
    }, []);

    const onSubmit = (values) => {
        dispatch(
            setCurrentActorByOrganization(values.organizationId, () => {
                window.location = "/";
            }),
        );
    };

    return (
        <Container className="h-100">
            <Row className="h-100">
                <Col md={3} />
                <Col md={6} className="d-flex flex-column justify-content-center">
                    <div className="form-wrapper bg-white p-4 rounded">
                        <Formik initialValues={{ organizationId: null }} onSubmit={onSubmit}>
                            {({ values, isSubmitting, setFieldValue }) => (
                                <FormikForm as={Form}>
                                    <Form.Group className="border-bottom">
                                        <h2>{I18n.t("forms.select_organization.title")}</h2>
                                    </Form.Group>
                                    <Form.Group>
                                        <p>{I18n.t("forms.select_organization.description")}</p>
                                    </Form.Group>
                                    <Form.Group>
                                        <Select
                                            name="organizationId"
                                            options={organizations}
                                            placeholder={I18n.t("forms.select_organization.inputs.select_placeholder")}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            onChange={(option) => setFieldValue("organizationId", option.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="text-center">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="text-white"
                                            disabled={isSubmitting || !values.organizationId}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("forms.select_organization.submit")}
                                        </Button>
                                    </Form.Group>
                                </FormikForm>
                            )}
                        </Formik>
                    </div>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
    );
};

export default SwitchOrganizationPage;
